<template>
  <el-select
    v-model="userId"
    placeholder="其他任务类型"
    style="width: 170px;margin-left:0"
    @change="changeValue"
    size="small"
    multiple
    collapse-tags
    filterable
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.taskTypeName"
      :value="item.id"
    />
  </el-select>
 
</template>

<script>
import { taskTypeList } from "@/api/crm";
export default {
  name: "selectMulTaskType",
  components: {},
  props: {
    userId: Array,
    hidden: Boolean,
  },
  data() {
    return {
      options: [],
      dialogTypeVisible: false,
      temp: {
        taskTypeName: "",
      },
    };
  },
  computed: {
    userId: {
      get() {
        if (!this.userId) {
          return [];
        }
        return this.userId;
      },
      set(val) {
        this.$emit("update:userId", val);
        this.$emit("success", val);
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      taskTypeList({}).then((res) => {
        this.options = res.data.data;
        if (this.hidden) {
          this.options = this.options.filter(
            (v) => v.id != 1 && v.id != 2 && v.id != 3 && v.id != 4
          );
        }
      });
    },
   
    // changeValue(val) {
    //   this.$emit("update:userId", this.userId);
    //   this.$emit("success", this.userId);
    // },
  },
};
</script>

<style scoped lang="scss"></style>
