<template>
  <div class="app-container">
    <div class="content_data">
      <div
        style="display: flex; align-items: center;width:98%;margin: 0 auto;height:70px"
      >
        <div style="width:8%">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div style="margin-left:5px;">
            <div style="margin-bottom:5px">企业总数</div>
              <span style="text-decoration: underline;cursor: pointer;" @click="cancel">{{ sum }}</span
              >家
          </div>
        </div>
        <el-tooltip effect="dark" content="账期为当月且状态为已授权的企业。" placement="top" >
          <div class="each">
            <div class="icon_bg">
              <i class="iconfont icon-zichan"></i>
            </div>
            <div style="margin-left:5px;">
                <div style="margin-bottom:5px">可采集企业</div>
              <span style="text-decoration: underline;cursor: pointer;" @click="getSx('cj')">{{ kcj }}</span
                >家
            </div>
          </div>
        </el-tooltip>
        <el-tooltip effect="dark" content="当期采集成功且收入支出有数据的企业。" placement="top" >
          <div class="each">
            <div class="icon_bg">
              <i class="iconfont icon-zichan"></i>
            </div>
            <div style="margin-left:5px;">
                <div style="margin-bottom:5px">有数据企业</div>
              <span style="text-decoration: underline;cursor: pointer;" @click="getSx('sj')">{{ ysj }}</span
                >家
            </div>
          </div>
        </el-tooltip>
        <el-tooltip effect="dark" content="因不在本账期或未取得授权不可采集" placement="top" >
          <div class="each">
            <div class="icon_bg">
              <i class="iconfont icon-zichan"></i>
            </div>
            <div style="margin-left:5px;">
                <div style="margin-bottom:5px">不可采集企业</div>
              <span>{{ sum - kcj }}</span
                >家
            </div>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <qzf-search-period v-model:period="listQuery.period" size="small" @success="getList"></qzf-search-period>
        <el-input size="small" style="width:200px;margin-left:5px" v-model="listQuery.name" @keyup.enter="getList" placeholder="请输入编号/公司名/授权银行" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 0px;width:430px" label-width="105px">
            <el-form-item label="纳税人类型：">          
              <selecttaxtype v-model:type="listQuery.type" style="width:130px;margin: 0;"></selecttaxtype>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:130px;margin: 0;"></selectuser>
            </el-form-item>
            <el-form-item label="税局：" > 
              <selectcity v-model:citys="listQuery.districtCode" style="width:130px;margin: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="公司类型："> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:130px;margin: 0;">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="随手记 ：">
              <el-input v-model="listQuery.jotDown" style="width:130px;margin: 0;" size="small" placeholder="请输入随手记" />
            </el-form-item>
            <el-form-item label="授权状态：">
              <el-radio-group v-model="listQuery.jzStatus">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="2">未授权</el-radio>
                <el-radio :label="1">已授权</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="采集状态：">
              <selectCjJzStatus v-model:allStatus="listQuery.allStatus"></selectCjJzStatus>
            </el-form-item>
          </el-form>
        </search>
        <!-- <span style="margin-left:10px"><el-switch v-model="listQuery.statType" active-text="只显示已授权企业" inactive-text="" @change="getList"></el-switch></span> -->
      </div>
      <div>
        <qzf-video vid="63dd60f5886f4fbcf136a14eefe7d983_6"></qzf-video>
        <el-button size="small" type="primary" @click="batchCollect" :disabled="!$buttonStatus('yhcj_yhcj')" :loading="cjLoading">
          <el-icon style="margin-right:3px;"><Aim /></el-icon>
           银行采集
        </el-button>
        <!-- <el-button size="small" type="primary" @click="revocation" :disabled="!$buttonStatus('yhcj_cxrw')" :loading="cxLoading">
          <el-icon style="margin-right:3px;"><RefreshLeft /></el-icon>
           撤销任务
        </el-button> -->
      </div>
    </div>
    <el-table :data="list" :height="contentStyleObj" border @sort-change="sortChange" v-loading="loading" stripe @selection-change="handleSelectionChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" align="center" width="55" :selectable="selectable" />
      <el-table-column  label="编号"  align="center" prop="sort" width="70" fixed="left" sortable="custom">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="公司名称" min-width="240" fixed="left">
        <template #default="scope">
          <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false">
            <template v-slot>
              <el-tooltip content="新增银行账户" placement="top" effect="dark" v-if="$buttonStatus('yhcj_xzyh')">
                <i class="iconfont icon-jiahao" style="color:var(--themeColor,#17a2b8);cursor: pointer;margin-left:10px" @click="addComBank(scope.row)">
                </i>
              </el-tooltip>
            </template>
          </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="district" label="税局" width="65" align="center">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>
      <el-table-column prop="bank" label="授权银行" min-width="160" >
        <template #default="scope">
          <div class="div_p div_p_bank" v-for="(item,index) in scope.row.items" :key="index">
            <p class="bank_left">{{item.bankName}}</p>
            <div>
              <el-popconfirm v-if="item.bankName" title="确认删除该银行账户吗" @confirm="deleteComBank(item,scope.row)">
                <template #reference>
                  <i style="color:red;cursor: pointer;" class="iconfont icon-shanchu" v-if="$buttonStatus('yhcj_sc')"></i>
                </template>
              </el-popconfirm>
              <el-tooltip content="编辑银行账户" placement="top" effect="dark">
                <i v-if="item.bankName && $buttonStatus('yhcj_bjyhxx')" style="color:var(--themeColor,#17a2b8);cursor: pointer;" class="iconfont icon-bianji23" @click="editComBank(item,scope.row)"></i>
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bank" label="授权状态" width="155" >
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
            <el-tooltip :content="item.xybh" :disabled="item?.xybh.indexOf('验证失败') == -1" placement="top">
              <span>
                <i :class="$bankAuthIcon(item.xybh)"></i>{{ $bankAuthStatus(item.xybh) }}
                <i class="iconfont icon-wenhao"  v-if="item?.xybh.indexOf('验证失败') > -1"></i>
              </span>
            </el-tooltip>
            <el-button v-if="!item.xybh || item.xybh.indexOf('验证失败')> -1"  @click="showAgreement(item,scope.row)" size="small" type="text" :disabled="!item.bankId || !$buttonStatus('yhcj_sq')">去授权</el-button>
            <el-button v-else  @click="showAgreement(item,scope.row)" size="small" type="text" :disabled="!item.bankId || !$buttonStatus('yhcj_bjsqxx')">编辑</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bank" label="采集状态" width="150" >
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
            <div class="item_icon" style="width:80px">
              <p>
                <el-tooltip :disabled="(item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:'') == ''?true:false" class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div v-html="item.errLog?item.errLog:'' + item.bussinessLog?item.bussinessLog:''"></div>
                  </template>
                  <div>
                    <div style="display:inline-block;" v-if="item.image && item.taskStatus == 3" class="demo-image__preview">
                      <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="item.bussinessStatus == 3" @click="open(item, index,scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="item.bussinessStatus == 2 " @click="open(item, index,scope.$index)"></i>
                    </div>
                    <i v-else :class="$batchSendIconCj(item.taskStatus, item.bussinessStatus)"></i>
                    <span style="padding-left: 0 ;">{{$batchSendCj(item.taskStatus, item.bussinessStatus)}}</span> 
                    <i class="iconfont icon-wenhao" v-if="(item.errLog && item.errLog != '成功')|| (item.bussinessLog && item.bussinessLog !='成功')" style="font-size:15px;color: red;margin-left:4px"></i>
                  </div>
                </el-tooltip>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bank" label="交易笔数" min-width="80" >
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
           <p v-if="item.bussinessStatus == 2">{{ item.count ? item.count : 0 }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bank" label="收入" min-width="80" >
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
           <p v-if="item.bussinessStatus == 2">{{ item.inAmount ? item.inAmount : 0 }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bank" label="支出" min-width="80" >
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
           <p v-if="item.bussinessStatus == 2">{{item.outAmount ? item.outAmount : 0 }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bank" label="采集时间" width="150" >
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.items" :key="index">
           <p>{{ item.beginAt }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="jotDown" label="随手记" min-width="150" show-overflow-tooltip>
        <template #default="scope">
          <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
          <span>{{scope.row.jotDown}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="manager" label="操作" width="190" fixed="right">
        <template #default="scope">
          <div class="div_p"  v-for="(item,index) in scope.row.items" :key="index">
            <el-button @click="handleUpdate(item,scope.row)" size="small" type="text" :disabled="!item.bankId || !item.xybh || item?.xybh.indexOf('验证中')>-1 || item?.xybh.indexOf('验证失败')>-1">采集</el-button>
            <el-button @click="declareDetail(item,scope.row)" size="small" type="text" :disabled="!item.bankId || item.taskStatus == 0">记录</el-button>
            <el-button @click="handleCheckDetail(item,scope.row)" size="small" type="text" :disabled="!$buttonStatus('yhcj_mx') || !item.count || item.count == '0'">明细</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="collectBank"/>
    </div>
  </div>
  <accreditBank ref="accreditBank" :bankName="bankName" :bankId="bankId" @success="getList" ></accreditBank>
  <declareDetails ref="declareDetails"></declareDetails>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
  <!-- 明细 -->
  <el-drawer
    class="elDrawerDetail"
    :title="comName"
    v-model="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    size="75%"
  >
    <div style="margin-bottom:10px">
      <el-date-picker
        class="rangeDate"
        style="width:200px;position:relative;top:3px;margin-right:10px"
        size="small"
        v-model="value1"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始账期"
        end-placeholder="结束账期"
        @change="changeMonth"
        value-format="YYYYMM"
      >
      </el-date-picker>
      <el-radio-group size="small" @change="changePeriod" v-model="detailPeriod" style="position:relative;top:-4px;margin-right:10px" class="radioButton">
        <el-radio-button label="month">本账期</el-radio-button>
        <el-radio-button label="season">本季度</el-radio-button>  
        <el-radio-button label="year">本年度</el-radio-button>  
      </el-radio-group>
      <!-- <el-button size="small" type="primary" style="float:right" @click="exportDetail">导出</el-button> -->
    </div>
    <el-table :data="detailList" border :height="contentStyleObj2" style="width: 100%" stripe>
      <el-table-column prop="invoiceName" label="收支方向" width="90">
        <template #default="scope">
          <span v-if="scope.row.type == 2">借</span>
          <span v-if="scope.row.type == 1">贷</span>
        </template>
      </el-table-column>
      <el-table-column label="账户名称" min-width="240"> 
        <template #default="scope">
          <span>{{ scope.row.otherName}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceDate" label="交易金额" min-width="90">
        <template #default="scope">
          <span>{{scope.row.amount}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" min-width="120" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.remark}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sumAmount" label="交易时间" min-width="90">
        <template #default="scope">
          <span>{{ $parseTime(scope.row.transDate, "{y}-{m}-{d}")}}</span>
        </template>
         </el-table-column>
      <el-table-column prop="sumTax" label="回单影像" width="130" align="center"> 
        <template #default="scope">
          <el-button type="text" :disabled="!scope.row.pdf" size="small" @click="openDetail(scope.row)">
            <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);font-size: 16px;margin-right: 4px;"  v-if="scope.row.pdf" ></i>
          <i class="iconfont icon-pdf" style="font-size: 16px;margin-right: 4px;" v-else></i><span>电子回单</span>
          </el-button>
         
        </template>
      </el-table-column>
    </el-table>
    <div style="font-size:13px;margin-top:10px;">
      汇总笔数:<span style="font-weight:600;margin-right:10px">{{detailInfo.count}}</span> 
      收入金额:<span style="font-weight:600;margin-right:10px">{{detailInfo.in_sum}}</span>  
      支出金额:<span style="font-weight:600;margin-right:10px">{{detailInfo.out_sum}}</span>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="detailTotal>0" :total="detailTotal" v-model:page="listQueryDetail.page" v-model:limit="listQueryDetail.limit" @pagination="getDetailList" />
    </div>

  </el-drawer>
  <bankAuth ref="bankAuth" @success="getList"/>
  <jotDown ref="jotDown" @jotDownUpdate="updateNote"/>
  <!-- 银行采集 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" title="银行采集" width="30%">
    <div class="collect_bg">
      <el-checkbox v-model="zzsJd">小规模季度采集</el-checkbox>
      <el-tooltip content="不勾选默认采当月" placement="top">
        <i class="iconfont icon-wenhao jdcj" style=""></i>
      </el-tooltip>
      <div class="footer">
        <el-button type="primary" size="small" @click="batch ? batchSendTask() : begin()" :loading="cjLoading">开始采集</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { sendTask } from "@/api/collection";
import { getCompanyBankList ,bankDggCollection } from "@/api/company";
import { bankNameDel,bankDetailList } from "@/api/bank"
import { currentAccountPeriod } from '@/utils'
import accreditBank from "./components/accreditBank.vue";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectCjJzStatus from '../company/components/selectCjJzStatus.vue'
import declareDetails from "./components/declareDetails.vue"
import { quitTask } from "@/api/collection"
import sbImgUrl from "./components/sbImgUrl.vue"
import selectcity from "@/components/Screening/selectcity";
import bankAuth from '@/components/bankAuth'
import jotDown from '@/components/jotDown'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'


export default {
  name: "collectBank",
  components:{
    selecttaxtype,
    accreditBank,
    selectCjJzStatus,
    declareDetails,
    sbImgUrl,
    selectcity,
    bankAuth,
    jotDown,
    TagNameCopy,
    TableSortCell
  },
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      orgId: this.$store.getters["user/user"].orgId,
      listQuery: {
        page: 1,
        limit: 20,
        period:currentAccountPeriod(),
        userId:null,
        type:"",
        taskStatus:[],
        companyType:'',
        count: 0,
        jotDown:'',
      },
      list: [],
      total: 0,
      loading:false,
      contentStyleObj:{},
      bankName:"",
      bankId:null,
      comId:0,
      comName:'',
      reqNos: [],
      contentStyleObj2:{},
      listQueryDetail:{
        startTime:"",
        endTime:"",
        bankId:0,
        limit:20,
        page:1,
      },
      value1:[],
      drawer:false,
      detailInfo:{},
      detailTotal:0,
      detailPeriod:'month',
      kcj:0,
      sum:0,
      ysj:0,
      cjLoading:false,
      cxLoading:false,
      selects:[],
      xgmJdCj:false,
      dialogFormVisible:false,
      zzsJd:false,
      collectParam:{},
      batch:0
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem('collectBank') ? localStorage.getItem('collectBank')*1 : 20
    this.contentStyleObj = this.$getHeight(280)
    this.contentStyleObj2 = this.$getHeight(250)
    this.getList();
  },
  methods: {
    getSx(type){
      if(type == 'cj'){
        this.listQuery.jzStatus = 1
      }else if(type == 'sj'){
        this.listQuery.jzStatus = 0
        this.listQuery.allStatus=["business-2"]
        this.listQuery.count = 1
      }
      this.getList()
    },
    smallNotes(row){
      this.$refs.jotDown.init(row.name,row.comId,this.listQuery.period)
    },
    //更新随手记之后的回调
    updateNote(id,text){
      this.list.map(v=>{
        if(v.comId == id){
          v.jotDown = text
        }
      })
    },
    // 重置
    cancel(){
      let originLimit =this.listQuery.limit
      this.listQuery={
        page: 1,
        limit: originLimit,
        period:currentAccountPeriod(),
        userId:null,
        type:"",
        taskStatus:[],
        districtCode:null,
        jzStatus:null,
        allStatus:null,
        companyType:'',
        count: 0,
        jotDown:'',
      }
      this.getList();
    },
    selectable(row){
      if (!row.items[0].bankId) {
        return false 
      }else{
        return true
      }
    },
    getList() {
      this.loading = true
      getCompanyBankList(this.listQuery).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.sum = res.data.data.sum;
          this.kcj = res.data.data.kcj;
          this.ysj = res.data.data.ysj;
        }
      });
    },
    //去授权
    showAgreement(item,row){
      this.bankName = item.bankName
      this.bankId = item.bankId
      this.$refs.accreditBank.init(row.comId,this.listQuery.period,item)
    },
    //采集
    handleUpdate(item,row){
      // let runStatus = this.isTaskRunning(row.items)
      // if(runStatus){
      //   this.$qzfMessage(`检测到公司存在相同银行不同主账号正在进行中的任务，请稍后发起`,1)
      //   return
      // }
      this.batch = 0
      this.collectParam = {
        taskName: 'cj-bank',
        comIds:[row.comId],
        gsMainIds:[item.bankId],
        period:this.listQuery.period,
        cond:{
          id:false
        }
      }
      if(this.orgId == 3576 && (item.bankName.match('工商银行')||item.bankName.match('建设银行') || item.bankName.match('民生银行') || (item.bankName.match('招商银行') && item.xybh == 'dgghassigned'))){
        this.loading = true
        bankDggCollection(this.collectParam).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage('任务已发起',3)
            this.getList();
          }else{
            this.getList();
          }
        })
      }else{
        let date = this.listQuery.period.slice(4,6)
        this.xgmJdCj = ['03','06','09','12'].includes(date) ? true : false
        if(this.xgmJdCj && row.type == '1'){
          this.dialogFormVisible = true
        }else{
          this.loading = true
          sendTask(this.collectParam).then(res => {
            if(res.data.msg == "success"){
              this.$qzfMessage('任务已发起',3)
              this.getList();
            }else{
              this.getList();
            }
          })
        }
      }
    },
    begin(){
      this.cjLoading = true
      this.collectParam.cond.jd = this.zzsJd
      sendTask(this.collectParam).then(res => {
        this.cjLoading = false
        if(res.data.msg == "success"){
          this.$qzfMessage('任务已发起',3)
          this.dialogFormVisible = false
          this.getList();
        }else{
          this.dialogFormVisible = false
          this.getList();
        }
      })
    },
    //撤销任务
    revocation(){
      if(this.reqNos.length == 0){
        this.$qzfMessage('请选择要撤销的任务',1)
        return
      }
      this.cxLoading = true
      quitTask({reqNos:this.reqNos}).then(res => {
          this.cxLoading = false
          if(res.data.msg == 'success') {
           this.getList();
          }
        })
    },
    hasDuplicateBank(items) {
      return items?.some((item, index) => {
        return items?.some((otherItem, otherIndex) => {
          return index !== otherIndex && item.yhName === otherItem.yhName && item.bankNo !== otherItem.bankNo;
        });
      });
    },
    isTaskRunning(items){
      return items?.some((item, index) => {
        return items?.some((otherItem, otherIndex) => {
          return (index !== otherIndex && item.yhName === otherItem.yhName && item.bankNo !== otherItem.bankNo) && [1,2,99].includes(otherItem.taskStatus) || ['1','2','99'].includes(item.taskStatus);
        });
      });
    },
    //批量采集
    batchCollect(){
      if(this.selects.length == 0){
        this.$qzfMessage('请选择公司',1)
        return
      }
      let status = false
      this.selects.map(v=>{
        v.items.map(e=>{
          if(!e.bankId || !e.xybh || e.xybh.indexOf('验证失败')>-1 || e.xybh.indexOf('验证中')>-1){
            status = true
            return
          }
        })
      })
      if(status){
        this.$qzfMessage('存在公司未授权银行，请重新选择',1)
        return
      }
      // let yhStatus = false
      // this.selects.map(v=>{
      //   yhStatus = this.hasDuplicateBank(v.items)
      //   if(yhStatus) return 
      // })
      // if(yhStatus){
      //   this.$qzfMessage(`检测到公司存在相同银行不同主账号，不可同时发起请重新选择！`,1)
      //   return
      // }
      // let runStatus = false
      // this.selects.map(v=>{
      //   runStatus = this.hasDuplicateBank(v.items)
      //   if(runStatus) return 
      // })
      // if(runStatus){
      //   this.$qzfMessage(`检测到公司存在相同银行不同主账号正在进行中的任务，请稍后发起`,1)
      //   return
      // }
      this.zzsJd = false
      this.batch = 1
      let date = this.listQuery.period.slice(4,6)
      this.xgmJdCj = ['03','06','09','12'].includes(date) ? true : false
      if(this.xgmJdCj){
        //过滤纳税人
        let typeStatus = false
        this.selects.map(v=>{
          if(v.type != this.selects[0].type){
            typeStatus = true
            return
          }
        })
        if(typeStatus){
          this.$qzfMessage("请选择相同纳税人类型公司", 1)
          return
        }
        if(this.selects[0].type == '1'){
          this.dialogFormVisible = true
        }else{
          this.batchSendTask()
        }
      }else{
        this.batchSendTask()
      }
    },
    async batchSendTask(){
      this.cjLoading = true
      let ids = []
      let dggGsyh = [] //顶呱呱工商银行
      let comIds = []
      this.selects.map(v=>{
        comIds.push(v.comId)
        v.items.map(e=>{
          if(this.orgId == 3576 && (e.bankName.match('工商银行')|| e.bankName.match('建设银行')||e.bankName.match('民生银行')|| (e.bankName.match('招商银行') && e.xybh == 'dgghassigned'))){
            dggGsyh.push(e.bankId)
          }else{
            ids.push(e.bankId)
          }
        })
      })
      if(dggGsyh.length != 0){
        let param = {
          taskName: 'cj-bank',
          comIds:comIds,
          gsMainIds:dggGsyh,
          period:this.listQuery.period,
        }
        await bankDggCollection(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage('任务已发起',3)
          }
        })
      }
      if(comIds.length != 0 && ids.length != 0){
        let param = {
          taskName: 'cj-bank',
          comIds:comIds,
          gsMainIds:ids,
          period:this.listQuery.period,
          cond:{
            jd:this.zzsJd
          }
        }
        await sendTask(param).then(res => {
          if(res.data.msg == "success"){
            this.$qzfMessage('任务已发起',3)
          }
        })
      }
      this.cjLoading = false
      this.dialogFormVisible = false
      this.getList();
    },
    handleSelectionChange(val){
      this.selects = val
      this.reqNos = []
      val.map(info=>{
        info.items.map(item=>{
          this.reqNos.push(item.reqNo)
        })
      })
    },
    //采集详情
    declareDetail(item,row) {
      this.$refs.declareDetails.init(row.comId,this.listQuery.period,item.taskName,item.bankId+'')
    },
    // 新增银行账户
    addComBank(row){
      this.$refs.bankAuth.init(row)
    },
    // 编辑银行账户
    editComBank(item,row){
      this.$refs.bankAuth.init(row,item)
    },
    // 删除银行账户
    deleteComBank(item){
      let param = {
        id:item.bankId
      }
      bankNameDel([param]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("删除成功")
          this.getList()
        }
      })
    },
    open(row, index,bigIndex) {
      let imageAll = []
      let currentIndex = 0
      this.list.map((res,i) => {
        res.items.map((v,k)=>{
          if(i<bigIndex || (i == bigIndex && k < index)){
            currentIndex++
          }
          let url = {
            name: res.name,
            url: ""
          }
          url.url = v.image ? 'https://file.listensoft.net' + v.image + "?" + Math.random(10): ""
          imageAll.push(url)
        })
      })
      this.$refs.sbImgUrl.dialog(imageAll, currentIndex)
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
    //银行明细
    handleCheckDetail(row,item){
      this.listQueryDetail.bankId = row.bankId
      this.listQueryDetail.startTime = this.listQuery.period
      this.listQueryDetail.endTime = this.listQuery.period
      this.listQueryDetail.page = 1
      this.listQueryDetail.limit = 20
      this.value1=[this.listQueryDetail.startTime,this.listQueryDetail.endTime]
      this.comName = item.name
      this.drawer = true
      this.getDetailList()
    },
    getDetailList(){
      bankDetailList(this.listQueryDetail).then(res=>{
        if(res.data.msg == 'success'){
          this.detailList = res.data.data.inInvoiceList ? res.data.data.inInvoiceList : []
          this.detailTotal = res.data.data.total
          this.detailInfo = res.data.data.statistic
        }
      })
    },
    changeMonth(){
      this.listQueryDetail.startTime = this.value1[0]
      this.listQueryDetail.endTime = this.value1[1]
      this.getDetailList()
    },
    changePeriod(){
      let year = this.listQuery.period.slice(0,4)
      let month = this.listQuery.period.slice(4,6)
      if(this.detailPeriod == 'month'){
        this.value1 = [year+month,year+month]
      }else if(this.detailPeriod == 'season'){
        if(month < '04'){
          this.value1 = [year+'01',year+'03']
        }else if(month < '07'){
          this.value1 = [year+'04',year+'06']
        }else if(month < '10'){
          this.value1 = [year+'07',year+'09']
        }else if(month > '09'){
          this.value1 = [year+'10',year+'12']
        }
      } else if(this.detailPeriod == 'year'){
        this.value1 = [year+'01',year+'12']
      }
      this.listQueryDetail.startTime = this.value1[0]
      this.listQueryDetail.endTime = this.value1[1]
      this.getDetailList()
    },
    openDetail(row) {
      if(row.pdf.indexOf('https://qgcs.oss-cn-beijing.aliyuncs.com')>-1){
        window.open(row.pdf);
      }else{
        window.open('https://file.listensoft.net' + row.pdf);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.div_p{
  font-size: 13px;
  line-height: 24px;
  height: 24px;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 4px;
  }
}
.div_p_bank{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bank_left{
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 13px;
    line-height: 13px;
    color: #333;
    margin-left: 4px;
  }
}
.el-radio{
  margin-right: 12px;
 }
 .elDrawerDetail .el-drawer__body{
    padding-top: 0px;
  }
  .content_data {
 
  background-color: #d8ecff;
  .each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.each span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
.iconfont {
  font-size: 18px;
  color: #17a2b8;
}
}
.collect_bg{
  background-color: #e7e7e759;
  padding: 10px;
  margin-bottom: 20px;
  .footer {
    display: flex;
    justify-content: end;
    padding: 10px 0;
  }
}
.jdcj{
  color:red;
  cursor: pointer;
  margin-left:4px;
}
.icon-wenhao{
  color:red;
  cursor: pointer;
}
</style>