<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogTableVisible"
      title="情况记录"
    >
      <div class="box">
        <el-scrollbar height="500px">
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in activities"
              :key="index"
              hide-timestamp
              icon="Document"
              color="var(--themeColor,#17a2b8)"
              size="large"
            >
              <div style="color: var(--themeColor,#17a2b8);font-size:14px">
                <span> {{ item.createName }}</span>
                &nbsp;&nbsp;{{
                  $parseTime(item.createdAt, "{y}-{m}-{d} {h}:{i}:{s}")
                }}
              </div>
              <div style="font-size: 12px; margin-top: 10px">
                {{ item.content }}
              </div>
            </el-timeline-item>
          </el-timeline>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { taskMemoryList } from "@/api/crm";
export default {
  name: "taskRecord",
  components: {},
  props: {},
  data() {
    return {
      dialogTableVisible: false,
      id: "",
      activities: [],
    };
  },
  mounted() {},
  methods: {
    init(row) {
      this.id = row.id;
      taskMemoryList({ taskId: this.id }).then((res) => {
        if (res.data.msg == "success") {
          this.activities = res.data.data;
          this.dialogTableVisible = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.box :deep(.el-timeline-item__content) {
  font-size: 18px !important;
}
.box :deep(.el-timeline-item__wrapper) {
  width: 70% !important;
}
</style>
