<template>
  <div class="app-container">
    <div class="top">
      <el-row :span="24">
        <el-col :xs="2" :sm="2" :lg="2">新增日期</el-col>
        <el-col :xs="10" :sm="10" :lg="10">
          <div class="flex_style">
            <el-radio-group
              v-model="listQuery.taskType"
              @change="getList"
              size="small"
            >
              <el-radio label="">全部</el-radio>
              <el-radio label="1">今天</el-radio>
              <el-radio label="2">近七天</el-radio>
              <el-radio label="3">本月</el-radio>
            </el-radio-group>
            <div class="flex_style">
              <!-- <span style="font-size:13px">自定义：</span> -->
              <el-date-picker
                v-model="listQuery.taskTime"
                type="date"
                placeholder="请选择开始时间"
                style="width: 120px"
                value-format="YYYY-MM-DD"
                size="small"
                @change="getList"
              />
              <span>-</span>
              <el-date-picker
                v-model="listQuery.taskEndTime"
                type="date"
                placeholder="请选择结束时间"
                style="width: 120px"
                value-format="YYYY-MM-DD"
                size="small"
                @change="getList"
              />
            </div></div
        ></el-col>
        <el-col :xs="2" :sm="2" :lg="2">截止日期 </el-col>
        <el-col :xs="10" :sm="10" :lg="10"
          ><div class="flex_style">
            <el-radio-group
              v-model="listQuery.endType"
              @change="getList"
              size="small"
            >
              <el-radio label="">全部</el-radio>
              <el-radio label="1">今天</el-radio>
              <el-radio label="2">近七天</el-radio>
              <el-radio label="3">本月</el-radio>
            </el-radio-group>
            <div class="flex_style">
              <!-- <span style="font-size:13px">自定义：</span> -->
              <el-date-picker
                v-model="listQuery.endTime"
                type="date"
                placeholder="请选择开始时间"
                style="width: 120px"
                value-format="YYYY-MM-DD"
                size="small"
                @change="getList"
              />
              <span>-</span>
              <el-date-picker
                v-model="listQuery.endEndTime"
                type="date"
                placeholder="请选择结束时间"
                style="width: 120px"
                value-format="YYYY-MM-DD"
                size="small"
                @change="getList"
              />
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :xs="2" :sm="2" :lg="2">任务状态</el-col>
        <el-col :xs="10" :sm="10" :lg="10">
          <div>
            <el-radio-group
              v-model="listQuery.taskStarts"
              @change="getList"
              size="small"
            >
              <el-radio label="">全部</el-radio>
              <el-radio label="未开启">未开启</el-radio>
              <el-radio label="进行中">进行中</el-radio>
              <el-radio label="已完成">已完成</el-radio>
              <el-radio label="已关闭">已关闭</el-radio>
            </el-radio-group>
          </div></el-col
        >
        <el-col :xs="2" :sm="2" :lg="2">指派日期 </el-col>
        <el-col :xs="10" :sm="10" :lg="10"
          ><div>
            <el-radio-group
              v-model="listQuery.assignStarts"
              size="small"
              @change="getList"
            >
              <el-radio label="">全部</el-radio>
              <el-radio label="0">未指派</el-radio>
              <el-radio label="1">已指派</el-radio>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :xs="2" :sm="2" :lg="2">完成日期</el-col>
        <el-col :xs="10" :sm="10" :lg="10">
          <div class="flex_style">
            <el-radio-group
              v-model="listQuery.completeType"
              size="small"
              @change="getList"
            >
              <el-radio label="">全部</el-radio>
              <el-radio label="1">今天</el-radio>
              <el-radio label="2">近七天</el-radio>
              <el-radio label="3">本月</el-radio>
            </el-radio-group>
            <div class="flex_style">
              <!-- <span style="font-size:13px">自定义：</span> -->
              <el-date-picker
                v-model="listQuery.completeTime"
                type="date"
                placeholder="请选择开始时间"
                style="width: 120px"
                value-format="YYYY-MM-DD"
                size="small"
                @change="getList"
              />
              <span>-</span>
              <el-date-picker
                v-model="listQuery.completeEndTime"
                type="date"
                placeholder="请选择结束时间"
                style="width: 120px"
                value-format="YYYY-MM-DD"
                size="small"
                @change="getList"
              />
            </div></div
        ></el-col>
        <el-col :xs="2" :sm="2" :lg="2">到期筛选 </el-col>
        <el-col :xs="10" :sm="10" :lg="10"
          ><div class="flex_style" style="padding-top: 5px">
            <el-select
              v-model="listQuery.endTypeSt"
              placeholder="请选择"
              size="small"
              style="width: 90px; margin: 0"
            >
              <el-option label="大于" :value="1"> </el-option>
              <el-option label="小于" :value="2"> </el-option>
            </el-select>
            <el-input
              v-model="listQuery.dayNumber"
              size="small"
              style="width: 100px; margin-left: 5px"
              placeholder="输入天数"
            >
            </el-input>
            <el-button size="small" type="primary" @click="getList"
              >搜索</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :xs="2" :sm="2" :lg="2">任务类型</el-col>
        <el-col :xs="10" :sm="10" :lg="10">
          <div class="flex_style">
            <el-checkbox-group
              size="small"
              v-model="listQuery.typeId"
              @change="getList"
            >
              <el-checkbox-button
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-checkbox-button
              > </el-checkbox-group
            ><selectMulTaskType
              :hidden="true"
              ref="selectTaskType"
              v-model:userId="listQuery.typeId"
              @success="getList"
              style="width: 120px"
            />
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2"></el-col>
        <el-col :xs="10" :sm="10" :lg="10"> </el-col>
      </el-row>
    </div>
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="getList()"
        ></el-button>
        <el-input
          size="small"
          placeholder="请输入客户名称/任务名称/创建人/执行人"
          style="width: 280px"
          v-model="listQuery.taskName"
          clearable
        ></el-input>
        <el-button type="primary" size="small" @click="getList">
          <el-icon><Search /></el-icon><span> 搜索</span>
        </el-button>
      </div>
      <div>
        <el-button
          type="primary"
          size="small"
          icon="Plus"
          @click="addTaskQuest"
          :disabled="!$buttonStatus('jykh_xzrw')"
          >新增任务</el-button
        >

        <el-button
          type="primary"
          size="small"
          plain
          @click="allotPerson"
          :disabled="!$buttonStatus('jykh_zpry')"
          >指派人员</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          @click="startTask"
          :disabled="!$buttonStatus('jykh_kqrw')"
          >开启任务</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          @click="updateTaskStarts"
          :disabled="!$buttonStatus('iykh_gxrwzt')"
          >更新任务状态</el-button
        >
        <el-button
          type="danger"
          size="small"
          plain
          @click="delTaskAll"
          :disabled="!$buttonStatus('jykh_scrw')"
          icon="Delete"
          >删除任务</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          @click="checkTaskType"
          :disabled="!$buttonStatus('jykh_rwlx')"
          >任务类型</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          @click="daochu"
          :disabled="!$buttonStatus('jykh_dc')"
          >导出</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        :height="contentStyleObj"
        stripe
        @selection-change="handleSelection"
        v-loading="loading"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column
          label="截止日期"
          align="center"
          prop="endTime"
          width="90"
        />
        <el-table-column
          prop="taskTypeName"
          label="任务类型"
          align="center"
          width="90"
        />
        <el-table-column
          prop="taskName"
          label="任务名称"
          align="taskName"
          min-width="200"
        >
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <div
                class="item_icon"
                style="
                  width: 90%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                <span>{{ scope.row.taskName }}</span>
              </div>
              <span
                @click="editTask(scope.row)"
                style="
                  cursor: pointer;
                  color: var(--themeColor, #17a2b8);
                  font-weight: 500;
                  margin-left: 10px;
                  margin-top: 1px;
                  font-size: 13px;
                "
              >
                <i
                  class="iconfont icon-bianji23"
                  style="font-size: 15px; font-weight: 590"
                ></i>
                <span
                  style="position: relative; display: inline-block; top: -1px"
                  >编辑</span
                >
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="客户名称" min-width="120">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <div
                class="item_icon"
                style="
                  width: 90%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                <span>{{ scope.row.customerName }}</span>
              </div>
              <el-tooltip
                :content="scope.row.linkman"
                placement="top"
                effect="dark"
              >
                <el-icon
                  style="color: var(--themeColor, #17a2b8); cursor: pointer"
                >
                  <user />
                </el-icon>
              </el-tooltip>
              <el-tooltip
                :content="scope.row.linkmanTel"
                placement="top"
                effect="dark"
              >
                <el-icon
                  style="
                    color: var(--themeColor, #17a2b8);
                    margin-left: 4px;
                    cursor: pointer;
                  "
                >
                  <phone />
                </el-icon>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="90"
        />
        <el-table-column
          prop="customerName"
          label="任务状态"
          align="center"
          width="100"
        >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.taskStarts == '进行中'">
              <p>
                <i class="iconfont icon-info"></i>
                <span class="sbz">进行中</span>
              </p>
            </div>
            <div class="item_icon" v-else-if="scope.row.taskStarts == '已完成'">
              <p>
                <i class="iconfont icon-duihao"></i>
                <span class="sbcg2">已完成</span>
              </p>
            </div>
            <div class="item_icon" v-else-if="scope.row.taskStarts == '已关闭'">
              <p>
                <i class="iconfont icon-gantanhao"></i>
                <span class="wsb">已关闭</span>
              </p>
            </div>
            <div class="item_icon" v-else>
              <p>
                <i class="iconfont icon-gantanhao"></i>
                <span class="wsb">未开启</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="annex" label="附件" align="center" width="70">
          <template #default="scope">
            <el-tooltip
              content="点击查看图片附件"
              placement="top"
              effect="dark"
            >
              <el-icon
                v-if="scope.row.annex"
                style="
                  font-size: 16px;
                  color: var(--themeColor, #17a2b8);
                  cursor: pointer;
                "
                @click="open(scope.row, scope.$index)"
              >
                <picture-filled />
              </el-icon>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="文件附件" align="center" min-width="100">
          <template #default="scope">
            <div
              class="div_p"
              v-for="(item, index) in scope.row.fileUrl"
              :key="index"
            >
              <p @click="openFile(item)" v-if="item">
                <el-icon style="color: #17a2b8; margin-right: 3px"
                  ><Folder /></el-icon
                >{{ filterFile(item) }}
              </p>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="customerName" label="备注" align="center" /> -->
        <el-table-column label="执行人" align="center" width="100">
          <template #default="scope">
            <span>{{ scope.row.executor }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="taskTime"
          label="新增任务时间"
          align="center"
          width="100"
        />
        <el-table-column
          label="完成日期"
          align="center"
          width="100"
          prop="completeTime"
        />
        <el-table-column label="情况记录" min-width="120">
          <template #default="scope">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex; align-items: center; width: 75%">
                <el-popover
                  v-model:visible="scope.row.visible"
                  placement="bottom"
                  :width="350"
                  trigger="click"
                >
                  <el-input
                    v-model="noteContent"
                    :rows="4"
                    type="textarea"
                    placeholder="请输入"
                  />
                  <div style="text-align: right; margin-top: 10px">
                    <el-button
                      size="small"
                      type="primary"
                      @click="scope.row.visible = false"
                      >取消</el-button
                    >
                    <el-button
                      size="small"
                      type="primary"
                      @click="editNote(scope.row, 2)"
                      >确定</el-button
                    >
                  </div>
                  <template #reference>
                    <el-icon
                      @click="smallNotes(scope.row)"
                      style="
                        color: var(--themeColor, #17a2b8);
                        margin-right: 4px;
                        cursor: pointer;
                      "
                    >
                      <edit />
                    </el-icon>
                  </template>
                </el-popover>
                <div class="span_style" @click="checkStatus(scope.row)">
                  {{ scope.row.memoryName }}
                </div>
              </div>
              <div>
                <el-button
                  type="text"
                  size="small"
                  @click="checkStatus(scope.row)"
                  >记录</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" style="margin-top: 10px">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList()"
          type="termCustom"
        />
      </div>
    </div>
  </div>
  <addTask ref="addTask" @success="getList" />
  <el-drawer
    title="任务类型"
    v-model="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    size="40%"
  >
    <div style="text-align: right; margin-bottom: 10px">
      <el-button type="primary" size="small" @click="addType">新增</el-button>
    </div>
    <el-table
      :data="typeList"
      style="width: 100%"
      border
      :height="contentStyleObj"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="名称" prop="taskTypeName" />
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button
            type="text"
            plain
            size="small"
            icon="Edit"
            @click="edit(scope.row)"
            v-if="![1, 2, 3, 4].includes(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            type="text"
            plain
            size="small"
            icon="Delete"
            @click="delType(scope.row)"
            v-if="![1, 2, 3, 4].includes(scope.row.id)"
            style="color: #f56c6c"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-drawer>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogTypeVisible"
    title="添加任务类型"
    width="400px"
    destroy-on-close
  >
    <el-form :model="temp">
      <el-form-item label="任务类型：" :label-width="formLabelWidth">
        <el-input
          v-model="temp.taskTypeName"
          style="width: 200px"
          size="small"
          placeholder="请输入任务类型"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogTypeVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="saveType()" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 批量分配弹窗 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="allotFormVisible"
    title="指派人员"
    width="500px"
  >
    <span>执行人：</span>
    <business code="termCustom" v-model:userId="value"></business>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="allotFormVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="saveAllot()" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogTaskVisible"
    title="更新任务状态"
    width="400px"
    destroy-on-close
  >
    <el-form :model="form">
      <el-form-item label="任务状态：" label-width="110px">
        <el-select
          v-model="form.taskStarts"
          placeholder="请选择任务状态"
          size="small"
          style="width: 200px; margin: 0"
        >
          <el-option label="已完成" value="已完成"> </el-option>
          <el-option label="已关闭" value="已关闭"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="任务完成时间："
        label-width="110px"
        v-if="form.taskStarts == '已完成'"
      >
        <el-date-picker
          v-model="form.completeTime"
          type="date"
          placeholder="请选择任务完成时间"
          style="width: 200px"
          value-format="YYYY-MM-DD"
          size="small"
          disabled
        />
      </el-form-item>
      <el-form-item
        label="原因："
        label-width="110px"
        v-if="form.taskStarts == '已关闭'"
      >
        <el-input
          size="small"
          v-model="form.reason"
          style="width: 200px; margin: 0"
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogTaskVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="saveStarts()" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <taskRecord ref="taskRecord" />
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="imgSrc"
  />
</template>

<script>
import addTask from "./components/addTask.vue";
import {
  delTask,
  taskTypeList,
  saveTaskType,
  delTaskType,
  taskList,
  saveExecutor,
  saveTask,
  saveTaskMemory,
  exportTask,
  getCustomerList,
} from "@/api/crm";
import selectTaskType from "./components/selectTaskType.vue";
import taskRecord from "./components/taskRecord.vue";
import selectMulTaskType from "./components/selectMulTaskType.vue";
import business from "./components/business.vue";
export default {
  name: "termCustom",
  components: {
    addTask,
    selectTaskType,
    taskRecord,
    selectMulTaskType,
    business,
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        taskType: "",
        taskStarts: "",
        endType: "",
        completeType: "",
        endTypeSt: 1,
      },
      typeOptions: [
        {
          label: "公司设立",
          value: 1,
        },
        {
          label: "公司变更",
          value: 2,
        },
        {
          label: "股权转让",
          value: 3,
        },

        {
          label: "公司注销",
          value: 4,
        },
      ],
      list: [],
      drawer: false,
      typeList: [],
      temp: {
        taskTypeName: "",
      },
      dialogTypeVisible: false,
      total: 0,
      contentStyleObj: {},
      sels: [],
      allotFormVisible: false,
      loading: false,
      value: null,
      form: {},
      dialogTaskVisible: false,
      noteContent: "",
      dialogConfirm: false,
      showImagePreview: false,
      imgSrc: [],
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem("termCustom")
      ? localStorage.getItem("termCustom") * 1
      : 20;
    this.getList();
    this.contentStyleObj = this.$getHeight(340);
  },
  methods: {
    getList() {
      this.loading = true;
      taskList(this.listQuery).then((res) => {
        this.loading = false;
        this.list = res.data.data.list ? res.data.data.list : [];
        this.list.map((v) => {
          v.fileUrl = v.file.split(",");
        });
        this.total = res.data.data.total;
      });
    },
    addTaskQuest() {
      this.$refs.addTask.init();
    },
    checkTaskType() {
      this.drawer = true;
      this.getTypeList();
    },
    handleSelection(val) {
      this.sels = val;
    },
    getTypeList() {
      taskTypeList({}).then((res) => {
        if (res.data.msg == "success") {
          this.typeList = res.data.data;
        }
      });
    },
    addType() {
      this.dialogTypeVisible = true;
      this.temp.taskTypeName = "";
    },
    edit(row) {
      this.temp = Object.assign({}, row);
      this.dialogTypeVisible = true;
    },
    saveType() {
      if (!this.temp.taskTypeName) {
        this.$qzfMessage("请输入名称", 1);
        return;
      }
      saveTaskType(this.temp).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.getTypeList();
          this.dialogTypeVisible = false;
          this.$refs.selectTaskType.init();
        }
      });
    },
    //删除任务类型
    delType(row) {
      this.$confirm("确定删除该任务类型吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delTaskType({
          id: row.id,
        })
          .then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("删除成功");
              this.getTypeList();
              this.$refs.selectTaskType.init();
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      });
    },
    //指派人员
    allotPerson() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择任务", 1);
        return;
      }
      this.allotFormVisible = true;
      this.value = [];
    },
    saveAllot() {
      if (this.value.length == 0) {
        this.$qzfMessage("请选择人员", 1);
        return;
      }
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.id);
      });
      saveExecutor({
        taskId: ids,
        executorId: this.value,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("指派成功");
          this.allotFormVisible = false;
          this.getList();
        }
      });
    },
    //开始任务
    startTask() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择任务", 1);
        return;
      }
      this.$confirm("确定开启任务吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        this.sels.map((v) => {
          ids.push(v.id);
        });
        saveExecutor({
          taskId: ids,
          taskStarts: "进行中",
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("任务已开始");
            this.getList();
          }
        });
      });
    },
    //更新任务状态
    updateTaskStarts() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择任务", 1);
        return;
      }
      this.dialogTaskVisible = true;
      this.getDay();
      this.form.taskStarts = "";
    },
    getDay() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day <= 9 ? "0" + day : day;
      this.form.completeTime = year + "-" + month + "-" + day;
    },
    saveStarts() {
      if (!this.form.taskStarts) {
        this.$qzfMessage("请选择状态", 1);
        return;
      }
      // if (this.form.taskStarts == "已完成" && !this.form.completeTime) {
      //   this.$qzfMessage("请选择任务完成时间", 1);
      //   return;
      // }
      if (this.form.taskStarts == "已关闭" && !this.form.reason) {
        this.$qzfMessage("请填写关闭原因", 1);
        return;
      }
      if (this.form.taskStarts != "已完成") {
        this.form.completeTime = "";
      }
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.id);
      });
      saveExecutor({
        taskId: ids,
        taskStarts: this.form.taskStarts,
        completeTime: this.form.completeTime,
        reason: this.form.reason,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("更新成功");
          this.getList();
          this.dialogTaskVisible = false;
        }
      });
    },
    smallNotes(row) {
      this.visible = true;
      this.dialogConfirm = false;
    },
    editNote(row, e) {
      if (e == 2) {
        this.dialogConfirm = true;
      }
      saveTaskMemory({
        taskId: row.id,
        content: this.noteContent,
      }).then((res) => {
        if (res.data.msg == "success") {
          row.visible = false;
          this.$qzfMessage("保存成功");
          this.getList();
        }
      });
    },
    //删除任务
    delTaskAll() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择任务", 1);
        return;
      }
      this.$confirm("确定删除任务吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        this.sels.map((v) => {
          ids.push(v.id);
        });
        delTask({ id: ids }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功");
            this.getList();
          }
        });
      });
    },
    open(row) {
      this.showImagePreview = true;
      this.imgSrc = row.annexImgs;
    },
    // 查看文件
    openFile(row) {
      window.open(row + "?" + new Date().getTime());
    },
    //查看情况记录
    checkStatus(row) {
      this.$refs.taskRecord.init(row);
    },
    //导出
    daochu() {
      let arr = this.sels.length != 0 ? this.sels : this.list;
      exportTask(arr).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    //编辑任务
    editTask(row) {
      this.$refs.addTask.init(row, "edit");
    },
    filterFile(type) {
      var match = type.match(/\/([^/?]+)$/);
      return match ? match[1] : null; // 返回匹配到的文件名，如果没有匹配到则返回null
    },
  },
};
</script>

<style scoped lang="scss">
.el-col-sm-10 {
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  div {
    padding-left: 2px;
    box-sizing: border-box;
  }
}
.el-col-sm-2 {
  border-right: 1px solid #b9b9b9;
  text-align: right;
  padding-right: 2px;
  font-size: 14px;
  height: 34px;
  background-color: #ecf7f9;
  line-height: 34px;
}
.el-row {
  display: flex;
  border-left: 1px solid #b9b9b9;
  border-bottom: 1px dashed #b9b9b9;
}
.el-row:first-child {
  border-top: 1px solid #b9b9b9;
}
.el-row:last-child {
  border-bottom: 1px solid #b9b9b9;
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.el-checkbox-group {
  padding-top: 5px;
}
.flex_style {
  display: flex;
  align-items: center;
}
.el-radio {
  margin-right: 4px;
}
.span_style {
  display: inline-block;
  cursor: pointer;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:deep(textarea) {
  min-height: 145px !important;
}
.item_icon {
  display: inline-block;
  // padding: 1px 12px;
  padding-right: 5px;
  cursor: pointer;
  i {
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 4px;
  }
}
:deep(.el-checkbox-button__inner) {
  padding: 5px 5px;
}
:deep(.el-radio__label) {
  padding-left: 4px;
}
.div_p {
  line-height: 28px;
  border-bottom: 1px solid #efefef;
  height: 28px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
  }
  &:last-child {
    border-bottom: none;
  }
}
</style>
