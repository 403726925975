<template>
  <el-select
    v-model="userId"
    multiple
    collapse-tags
    placeholder="选择人员"
    style="width: 200px"
    @change="changeValue"
    filterable
    collapse-tags-tooltip
    size="small"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.CnName"
      :value="item.id"
    />
  </el-select>
</template>

<script>
import { findPeople } from '@/api/base'
export default {
  name: "business",
  components: {},
  props: {
    userId: Array,
    code: '',
  },
  data() {
    return {
        options:[],
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      findPeople({code:this.code}).then(res => {
        this.options = res.data.data.list
      })
    },
    changeValue(val) {
      this.$emit('update:userId', this.userId)
      this.$emit("success", this.userId)
    },
    clearValue(){
      this.userId = null
    }
  },
};
</script>

<style scoped lang="less"></style>
